import React, { Fragment, useState, useEffect } from 'react';
import { bool } from 'prop-types';
import { IconButton, Box } from '@chakra-ui/react';
import { MdMusicNote, MdMusicOff } from 'react-icons/md';

import isDevelopmentMode from '@invitato/helpers/dist/isDevelopmentMode';

import { DISABLED_MUSIC_IN_DEVELOPMENT } from '@/constants/feature-flags';
import { NAVIGATION_COLOR, DEFAULT_PROPS } from '@/constants/colors';
import SONG_CDN_URL from './assets/bg-sound.mp3';

function FloatingMusic({ shouldShowMusicIcon }) {
  const [play, setPlay] = useState(true);
  const [musicId, setMusicId] = useState('myAudio');

  const IconType = play ? MdMusicNote : MdMusicOff;

  /**
   * Side-effect to enable/disable music
   * @important Only disable music IF in development mode
   */
  useEffect(() => {
    if (DISABLED_MUSIC_IN_DEVELOPMENT && isDevelopmentMode()) {
      setMusicId('DISABLED_MUSIC');
    }
  }, []);

  /**
   * This function built-in with html5 function
   * doc: https://www.w3schools.com/tags/ref_av_dom.asp
   */
  const toggleMusic = () => {
    try {
      const myAudio = document.getElementById('myAudio');
      if (play) {
        myAudio.pause();
      } else {
        myAudio.play();
      }

      setPlay(!play);
    } catch {
      console.error('SOMETHING_WRONG_WHEN_PLAYING_MUSIC');
    }
  };

  return (
    <Fragment>
      <Box>
        <audio id={musicId} loop className="hide">
          <source src={musicId === 'DISABLED_MUSIC' ? '' : SONG_CDN_URL} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>

        {shouldShowMusicIcon && (
          <IconButton
            {...DEFAULT_PROPS.button}
            bgColor={NAVIGATION_COLOR.base}
            padding="0"
            minWidth="unset"
            height="32px"
            width="32px"
            borderRadius="50%"
            position="fixed"
            zIndex="999"
            bottom="12px"
            left="48px"
            boxShadow="md"
            onClick={toggleMusic}
            icon={<IconType color={NAVIGATION_COLOR.icon} />}
          />
        )}
      </Box>
    </Fragment>
  );
}

FloatingMusic.propTypes = {
  shouldShowMusicIcon: bool.isRequired,
};

export default React.memo(FloatingMusic);
